<template>
    <main-layout>

    <h1 class="mb-4">Välkommen!</h1>

    <p v-if="!courtsNotEmpty">Det finns inga tingsrätter tilldelade, vänligen tillsätt tingsrätter på denna medlem!</p>

    <h3 v-if="courtsNotEmpty">Registrera ärenden</h3>

    <select-field
      :options="courts"
      label=""
      :value.sync="selectedCourt"
      v-if="courtsNotEmpty"
      placeholder="Välj domstol"
      :editable="true"
      :fullWidth="true"
      :clearable="true"
      :useStore="true"
      dispatch="setCourt"
    ></select-field>

    <div class="d-flex justify-content-end" v-if="courtsNotEmpty">
      <button class="btn btn-primary mt-4" @click="goToMenu" :class="{'disabled': !courtSelected}">
        Fortsätt
      </button>
    </div>

    <div class="d-flex justify-content-end">
      <button class="btn btn-primary mt-4" @click="goToCalendar">Till Kalendern</button>
    </div>
    </main-layout>

</template>

<script>
// import BojAPI from '@/api/boj'
import mainLayout from '@/views/Layouts/Main'
import SelectField from '../components/fields/SelectField.vue';

export default {
  components: {
    mainLayout,
    SelectField
  },
  data() {
    return {
      frontendUrl: process.env.VUE_APP_FRONTEND_LINK,
      courts: [],
      selectedCourt: null
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.loadCourts()
    })
  },
  mounted() {
    this.getSelectedCourt()
  },
  computed: {
    courtSelected() {
      return this.$store.getters.selectedCourt != null;
    },
    courtsNotEmpty() {
      return Object.keys(this.courts).length !== 0 ? true : false;
    }
  },
  methods: {
    loadCourts() {
      this.courts = this.$store.getters.options.courts;
    },
    getSelectedCourt() {
      this.selectedCourt = this.$store.getters.selectedCourt;
    },
    goToMenu() {
      this.$router.push('/testimony');
    },
    goToCalendar() {
      window.location.href = this.frontendUrl + '/calendar';
    }
  }
}
</script>

<style>

</style>
