<template>
    <a @click="scrollTop" :class="{'is-visible': visible}" class="bottom-right">
        <i class="fas fa-arrow-up"></i>
    </a>
</template>

<script>
export default {
  data () {
    return {
      visible: false
    }
  },
  methods: {
    scrollTop: function () {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    },
    scrollListener: function (e) {
      this.visible = window.scrollY > 150
    }
  },
  mounted: function () {
    window.addEventListener('scroll', this.scrollListener)
  },
  beforeDestroy: function () {
    window.removeEventListener('scroll', this.scrollListener)
  }
}
</script>

<style scoped>
.bottom-right {
  position: fixed;
  bottom: -60px;
  right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  cursor: pointer;
  border-radius: 50%;
  background: #3f6c5d;
  padding: 10px;
  transition: bottom ease 300ms;
}

.is-visible {
    bottom: 30px;
}

.fa-arrow-up {
    font-size: 18px;
    text-align: center;
    vertical-align: middle;
    color: white;
}
</style>
