<template>
  <main-layout>

  <h2 class="mt-3 mb-0 fw-light">Statistikinmatning</h2>
  <p class="fw-light">{{selectedCourtLabel}}</p>

  <!-- <div class="d-flex flex-column mt-4">
    <button class="btn btn-primary mb-4" @click="addTestimony">Lägg till nytt mål</button>
    <button class="btn btn-primary mb-4" @click="addTime">Lägg till nedlagd tid</button>
    <router-link class="btn btn-primary mb-4" to="/testimony/registed">Tidigare registrerat</router-link>
  </div> -->

  <div class="row mt-4">
    <div class="col-12 col-md-6 col-lg-4 mb-4">
      <div class="card text-primary h-100">
        <div class="d-flex justify-content-center mt-3">
          <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 60px"/>
        </div>
        <div class="card-body d-flex flex-column">
          <h5 class="card-title">Nytt mål</h5>
          <p class="card-text">Skapa ett nytt mål här.</p>
          <button class="btn btn-primary align-self-start mt-auto" @click="addTestimony">Lägg till mål</button>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4 mb-4">
      <div class="card text-primary h-100">
        <div class="d-flex justify-content-center mt-3">
          <font-awesome-icon :icon="['far', 'clock']" style="font-size: 60px"/>
        </div>
        <div class="card-body d-flex flex-column">
          <h5 class="card-title">Nedlagd tid</h5>
          <p class="card-text">Registrera din nedlagda tid här.</p>
          <button class="btn btn-primary align-self-start mt-auto" @click="addTime">Registrera tid</button>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4 mb-4">
      <div class="card text-primary h-100">
        <div class="d-flex justify-content-center mt-3">
          <font-awesome-icon :icon="['fas', 'box-archive']" style="font-size: 60px"/>
        </div>
        <div class="card-body d-flex flex-column">
          <h5 class="card-title">Tidigare registrerat</h5>
          <p class="card-text">Ändra eller hitta ett registrerat mål här.</p>
          <router-link class="btn btn-primary align-self-start mt-auto" to="/testimony/registed">Visa</router-link>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-end">
    <router-link to="/main-menu">
      <button class="btn btn-primary mb-4">Välj annan domstol</button>
    </router-link>
  </div>

  <div class="d-flex justify-content-end">
      <button class="btn btn-primary mb-4" @click="goToCalendar">Till Kalendern</button>
  </div>
  </main-layout>
</template>

<script>
import mainLayout from '@/views/Layouts/Main'
import BojAPI from '@/api/boj'

export default {
  components: {
    mainLayout
  },
  data() {
    return {
      frontendUrl: process.env.VUE_APP_FRONTEND_LINK,
      newTestimony: {
        court: {},
        court_contact_types_id: null
      }
    }
  },
  mounted() {
    this.newTestimony.court = this.selectedCourt();
    this.newTestimony.court_contact_types_id = this.defaultCourtContactType();
  },
  computed: {
    selectedCourtLabel() {

      if (this.selectedCourt()) {
        return this.selectedCourt().label;
      } else {
        return '';
      }
    },
    selectedCourtId() {

      if (this.selectedCourt()) {
        return this.selectedCourt().id;
      } else {
        return null;
      }
    }
  },
  methods: {
    addTestimony() {
      this.$Progress.start()

      BojAPI.createTestimony(this.newTestimony)
      .then((response) => {
        this.$router.push('/testimony/new/' + response.data.id)
      })
    },
    addTime() {
      this.$Progress.start()

      BojAPI.createTestimonyTime('court_id', this.selectedCourtId)
      .then((response) => {
        this.$router.push('/testimony/time/' + response.data.id)
      }).catch((error) => {
        if (error.response.status == 409) {
          this.$toast.error('Tiden är redan registrerad för idag', {
            position: 'top-center',
          });
        } else {
          this.$toast.error('Något gick fel');
        }
      })
    },
    showRegistedTestimony() {
      this.$router.push('/testimony/registed')
    },
    selectedCourt() {
      let court = this.$store.getters.options.courts.find((court) => court.id === this.$store.getters.selectedCourt ) ?? null

      return court;
    },
    defaultCourtContactType() {
      let courtContactType = this.$store.getters.options.court_contact_types ? this.$store.getters.options.court_contact_types.id : null;

      return courtContactType;
    },
    goToCalendar() {
      window.location.href = this.frontendUrl + '/calendar';
    }
  }
}
</script>
