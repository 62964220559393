<template>
  <main-layout>

    <transition name="fade" mode="out-in">
      <div  v-if="!isSubRoute">

        <div class="d-flex justify-content-between align-items-baseline">
          <h2>Nedlagd tid</h2>

          <div class="d-flex justify-content-end">
            <router-link to="/testimony" class="btn btn-primary">Tillbaka</router-link>
          </div>
        </div>

        <div class="widget">
          <div class="widget-body bt-1 pr-0 pl-3">
            <div class="container-fluid">
              <h3 class="text-dark">Rapportera</h3>
              <div class="d-flex flex-column mt-4 mb-4">
                <div v-for="type in timeTypesOptions" :key="type.id" class="d-flex align-items-center mb-4">
                  <info-box class="info-box" :field='"Domstol - Nedlagd tid - " + type.label' />
                  <button class="btn btn-primary" @click="addTimeSpent(type)" append>{{type.label}}</button>
                  <i v-if="getTimeSpent(type)" class="time-added fas fa-check"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <router-view
        :timeModel.sync="timeModel"
        :refreshTime="refreshTime"
      />

    </transition>

  </main-layout>
</template>

<script>
import BojAPI from '@/api/boj'
import InfoBox from '@/components/fields/InfoBox';
import mainLayout from '@/views/Layouts/Main'

export default {
  components: {
    mainLayout,
    InfoBox
  },
  data() {
    return {
      timeModel: null,
      timeTypesOptions: []
    }
  },
  mounted() {
    this.setStoreData();
  },
  beforeRouteEnter (to, from, next) {
    BojAPI.getTestimonyTime(to.params.id)
    .then((response) => {
      next((vm) => {
        vm.setData(response.data)
      });
    })
    .catch((error) => {
      if (error.response.status === 404) {
        next({
          path: '/testimony'
        })
      }
    })
  },
  computed: {
    isSubRoute() {
      return this.$route.matched.length > 1
    },
    courtId() {
      return this.$store.getters.selectedCourt ?? null
    },
    timeId() {
      return this.$route.params.id
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    addTimeSpent(timeType) {
      this.$router.push({ path: '/testimony/time/'+ this.timeId +'/add/' + timeType.id})
    },
    setStoreData() {
      this.timeTypesOptions = this.$store.getters.options.testimony_time_types;
    },
    setData(payload) {
      this.timeModel = payload.data;
    },
    getTimeSpent(timeType) {
      const timeSpent = this.timeModel.testimony_time_types.find((type) => type.id == timeType.id);

      if (timeSpent) {
        return timeSpent.pivot.time
      }

      return null
    },
    refreshTime() {
      return BojAPI.getTestimonyTime(this.timeModel.id)
      .then((response) => {
        this.setData(response.data)
      })
      .catch((error) => {

        if (error.response.status == 404) {
          this.$router.push({ path: '/testimony'})
          return;
        }

        this.$toast.error('Något gick fel - refreshTestimony', {
          position: 'top-center',
        });
      })
    },
  }
}
</script>

<style scoped>

.info-box >>> > button {
  color: #406c5d;
}

</style>
