<template>
  <h1><i class="fas fa-circle-notch fa-spin"></i> Du loggas ut nu</h1>
</template>

<script>
export default {
  mounted: function () {
    document.body.classList.add('logout');
  },
  created() {
    document.body.classList.add('logout');

    this.$store.dispatch('logout')
    .then(() => {
      setTimeout(() => {
        this.$router.push('/login');
      }, 2000);
    })
  },
   destroyed: function () {
    document.body.classList.remove('logout');
  }
}
</script>
