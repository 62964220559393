<template>
  <main-layout>

    <h1>Uhno, sidan finns inte!</h1>

    <button class="btn btn-primary" @click="goBack">Tillbaka till huvudmenyn</button>

  </main-layout>

</template>

<script>
import mainLayout from '@/views/Layouts/Main'

export default {
  components: {
    mainLayout
  },
  methods: {
    goBack() {
      this.$router.push('/testimony');
    }
  }
}
</script>

<style>

</style>
