<template>

  <main-layout>

    <div class="d-flex justify-content-between align-items-baseline">
      <h2>Tidigare registrerat</h2>

      <router-link to="/testimony">
        <button class="btn btn-primary">Tillbaka</button>
      </router-link>
    </div>

    <reactive-base
      v-if="user"
      app="witness_index"
      :credentials="user.es_username + ':' + user.es_password"
      :url="elasticUrl"
    >

    <!-- <state-provider v-if="stats">
      <div class="col-12" :includeKeys="['value', 'hits', 'aggregations', 'error']" slot-scope="{ searchState }">
        <div class="row">

          <div class="col-md-4" v-if="searchState">
            <div class="widget">
              <div class="widget-header compact">
                <div class="widget-header-label">
                  <h3>
                    Information
                  </h3>
                </div>
              </div>
              <div class="widget-body pt-3 pb-4">
                <div class="row">
                  <div class="col text-center br-1">
                    <div class="text-big text-big-xl">
                      {{ getBucketCount(searchState, 'SearchResult', $field('id'), $t('labels.higher')) }}
                    </div>
                    <div class="label">
                      Antal mål
                    </div>
                  </div>
                  <div class="col text-center br-1">
                    <div class="text-big text-big-xl">
                      {{ getBucketCount(searchState, 'DataControllerSensor', 'persons', 'total') }}
                    </div>
                    <div class="label label-red">
                      Antal personer
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </state-provider> -->

    <div class="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
      <div class="widget testimony-list-elastic">
        <div class="widget-header">
          <div class="widget-header-label">
            <h3>
              Mål
            </h3>
          </div>

          <div class="widget-header-toolbar">
            <div class="actions" v-show="enableFilter">
							<span v-for="facet in getFacetsByLevel(1)" :key="facet.id">
								<div
                    :class="{
										'filter-action-sm': facet.small,
										'filter-action': facet.type === 'list',
										'search-action': facet.type === 'search',
									}"
                    class="ml-3"
                    v-if="checkFacetPermission(facet.permission)"
                >
									<multi-dropdown-list
                      v-if="facet.type === 'list'"
                      :placeholder="facet.placeholder"
                      :componentId="facet.id"
                      :URLParams="true"
                      sortBy="asc"
                      :size="300"
                      :nestedField="facet.nestedField"
                      fuzziness="AUTO"
                      searchPlaceholder="Sök..."
                      :showSearch="true"
                      :filterLabel="facet.label"
                      :dataField="facet.field"
                      :react="{ and: getOtherFilterIds(facet.id) }"
                  />

									<data-search
                      v-if="facet.field === 'person_name'"
                      componentId="search"
                      dataField="person_name"
                      iconPosition="right"
                      className="data-search"
                      :showClear="true"
                      :URLParams="true"
                      fuzziness="AUTO"
                      placeholder="Person..."
                      :debounce="100"
                      :autosuggest="false"
                      filterLabel="Sök"
                  />

									<data-search
                      v-if="facet.type === 'search' && facet.field !== 'person_name'"
                      :componentId="facet.id"
                      :dataField="facet.field"
                      iconPosition="right"
                      className="data-search"
                      :showClear="true"
                      :URLParams="true"
                      fuzziness="AUTO"
                      :placeholder="facet.placeholder"
                      :debounce="100"
                      queryFormat="and"
                      :autosuggest="false"
                      :filterLabel="facet.label"
                  />


                  <ReactiveComponent
                    v-if="facet.type === 'custom-datepicker'"
                    :componentId="facet.id"
                    :URLParams="true"
                    :filterLabel="facet.label"
                    :react="{ and: getOtherFilterIds(facet.id) }"
                  >
                    <div slot-scope="{ aggregations, setQuery, value }">
                      <date-picker
                        opens="left"
                        :placeholder="facet.placeholder"
                        :dataField="facet.field"
                        :aggregations="aggregations"
                        :setQuery="setQuery"
                        :selectedValue="value"
                        :show-dropdowns="facet.showDropdowns"
                        :single-date-picker="facet.singleDatePicker"
                      ></date-picker>
                    </div>
                  </ReactiveComponent>

								</div>
							</span>

              <!-- <div class="btn btn-grey btn-bold ml-3" @click="toggleFilter" v-show="!showFilter">
                <i class="fas fa-filter"></i>
              </div>

              <div class="btn btn-success btn-bold ml-3" @click="toggleFilter" v-show="showFilter">
                <i class="fas fa-filter"></i>
              </div> -->

            </div>
          </div>

        </div>

        <div class="widget-body no-padding">

          <reactive-list
              @queryChange="getQueryChange"
              @pageChange="pageChange"
              :defaultQuery="customUserQuery"
              :showResultStats="false"
              componentId="SearchResult"
              :dataField="getSortBy"
              :sortBy="getSortByOrder ? 'desc' : 'asc'"
              className="result-list-container"
              :innerClass="{
                  pagination: 'pagination menu justify-content-center pt-4',
              }"
              :from="0"
              :size="15"
              :react="{ and: getOtherFilterIds('foo') }"
            >

            <div class="row" slot="render" slot-scope="{ error, data }">

              <div class="item col-12 col-sm-6 col-lg-4" v-for="item in data" :key="item.id">
              <router-link :to="'/testimony/edit/' + item.id" custom v-slot="{ navigate }">
                <div class="box" role="button" @click="navigate" @keypress.enter="navigate">
                  <div class="row align-items-center mb-3">

                    <div class="col">
                      <h5>#{{item.id}}</h5>
                    </div>
                    <div class="col-5 created d-flex flex-column align-items-end">
                      <label class="label">Rättegångsdatum</label>
                      <span class="time">{{item.case_date ? moment(item.case_date).format("YYYY-MM-DD:HH:mm") : '-'}}</span>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-6">

                      <div class="list overview-middle pb-2">
                        <div class="list-item">
                          <label>Typ av mål</label>
                          <span v-if="item.testimony_type">{{item.testimony_type}}</span>
                          <span v-if="!item.testimony_type">-</span>
                        </div>
                      </div>

                      <div class="list overview-middle pb-2">
                        <div class="list-item" v-if="item.testimony_type == 'Brottmål'">
                          <label>Brottstyp</label>
                          <span v-if="item.crime">{{item.crime}}</span>
                          <span v-if="!item.crime">-</span>
                        </div>
                        <div class="list-item" v-if="item.testimony_type == 'Civilmål'">
                          <label>Civilmålstyp</label>
                          <span v-if="item.testimony_other_type">{{ item.testimony_other_type }}</span>
                          <span v-if="!item.testimony_other_type">-</span>
                        </div>
                      </div>

                    </div>
                    <div class="col-6">
                      <div class="list overview-middle pb-2">
                        <div class="list-item">
                          <label>Domstol</label>
                          <span>{{item.court}}</span>
                        </div>
                        <div class="list-item">
                          <label>Personer</label>
                          <span>{{ item.persons ? item.persons.length : 0 }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <td>{{getNumberOfPeople(item.persons)}}</td> -->

                <!-- <v-guard :permissions="['ticket-access-all-tickets']">
                  <td>{{ item.office }}</td>
                </v-guard>
                <v-guard :permissions="['ticket-access-only-local-office', 'ticket-access-office']">
                  <td>{{ item.municipality }}</td>
                </v-guard> -->

              </router-link>
              </div>
            </div>



            <template slot="renderNoResults">
              <div class="empty-placeholder pt-5 d-flex align-items-center justify-content-center">
                <!-- <i class="fa fa-times-circle"></i> -->

                <p><i class="fas fa-circle-xmark"></i> Inga resultat hittades</p>
              </div>
            </template>


          </reactive-list>
        </div>
      </div>
    </div>

    </reactive-base>
    <ScrollTopArrow />

  </main-layout>

</template>

<script>
import BojAPI from '@/api/boj'
import mainLayout from '@/views/Layouts/Main'
import DatePicker from '@/components/reactive/DatePicker'
import ScrollTopArrow from '@/components/ScrollToTop'
import moment from 'moment';

export default {
  components: {
    mainLayout,
    DatePicker,
    ScrollTopArrow
  },
  data() {
    return {
      elasticUrl: process.env.VUE_APP_API_ELASTIC,
      moment: moment,
      enableFilter: true,
      getSortByOrder: true,
      getSortBy: 'updated_at',
      showFilter: false,
      latestQuery: '',
      hide: [],
      testimonyTypes: [],
      facets: [
         {
          level: 1,
          id: 'searchID',
          visible: true,
          field: 'id_searchable',
          label: this.$t('labels.case')+'#',
          placeholder: this.$t('labels.case')+'#..',
          type: 'search',
          permission: null,
        },
      ],

    }
  },
  mounted() {
    if (
        Object.keys(this.$route.query).some(function (k) {
          return k.endsWith('SubFilter');
        })
    ) {
      this.showFilter = true;
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sort = urlParams.get('sort');
    const sortOrder = urlParams.get('sortOrder');

    if (sort) {
      this.getSortBy = sort;
    }
    if (sortOrder) {
      this.getSortByOrder = sortOrder === 'true';
    }
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    court_id() {
      return this.$store.getters.selectedCourt
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setStoreData();
    })
  },
  methods: {
    isCrimeOrCivilCase(type) {
      if (!this.testimony.testimony_type) return false;

      let selectedType = this.testimonyTypes.find(type => type.id == this.testimony.testimony_type.id);

      if (selectedType && selectedType.label == type) {
        return true;
      }

      return false
    },
    getNumberOfPeople(persons) {
      if (persons !== null) {
        return persons.length
      } else {
        return 0;
      }

    },

    customUserQuery() {
      return {
        query: {
          query: {
            bool: {
              must: [
                {
                  match: {'court_id': this.court_id },
                },
              ],
            },
          },
        }
      }
    },

    /**
     * Page Change Hook.
     *
     * Added to avoid scrolling of page when navigating through
     * the pages using the reactivesearch pagination.
     *
     */
    pageChange() {
    },


    /**
     * Store any changes to the Elasticsearch query
     */
    getQueryChange(prevQuery, nextQuery) {
      this.latestQuery = nextQuery;
    },

    /**
     * Add filter ids not present in facets.
     *
     * @param id
     * @returns {*[]}
     */
    getOtherFilterIds(id) {
      let ids = this.facets.filter((a) => a.id !== id).map((a) => a.id);
      ids.push('DataControllerSensor');
      // ids.push('createdDate');
      // ids.push('updateAtDate');

      return ids;
    },

    /**
     * Returns facets given by their level value.
     *
     * @param level
     * @returns {({visible: boolean, field: string, level: number, id: string, label: string, placeholder: string, type: string}|{visible: boolean, field: string, level: number, id: string, label: string, placeholder: string, type: string}|{visible: boolean, field: string, level: number, nestedField: string, id: string, label: string, placeholder: string, type: string}|{visible: boolean, field: string, level: number, id: string, label: string, placeholder: string, type: string}|{visible: boolean, field: string, level: number, id: string, label: string, placeholder: string, type: string})[]}
     */
    getFacetsByLevel(level) {
      let filters = this.facets.filter((a) => a.level === level);
      let hiddenFields = this.hide;
      filters = filters.filter(function (el) {
        return !hiddenFields.includes(el.field);
      });
      return filters;
    },
    checkFacetPermission(permission) {
      if (permission === null) {
        return true;
      }

      return this.$store.getters.permissions.includes(permission);
    },

    /**
     * Toogle extra fields
     */
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },

    /**
     * Change sort attribute for the results.
     *
     * @param value
     */
    changeSortBy(value) {
      this.getSortBy = value;
      this.getSortByOrder = !this.getSortByOrder;

      // this.updateQueryStringParam('sort', value);
      // this.updateQueryStringParam('sortOrder', this.getSortByOrder);
    },

    /**
     * Determine if a field is currently used for sorting.
     *
     * @param field
     * @param direction
     * @returns {boolean}
     */
    isSortField(field, direction) {
      return this.getSortBy === field && this.getSortByOrder === direction;
    },

    /**
     * Updates the query string paramter.
     *
     * Currently used for adding / replacing the current sort.
     *
     * @param key
     * @param value
     */
    updateQueryStringParam(key, value) {
      const baseUrl = [location.protocol, '//', location.host, location.pathname].join('');
      const urlQueryString = document.location.search;
      const newParam = key + '=' + value;
      let params = '?' + newParam;

      // If the "search" string exists, then build params from it
      if (urlQueryString) {
        const keyRegex = new RegExp('([?&])' + key + '[^&]*');

        // If param exists already, update it - otherwise, add it to end of query string
        if (urlQueryString.match(keyRegex) !== null) {
          params = urlQueryString.replace(keyRegex, '$1' + newParam);
        } else {
          params = urlQueryString + '&' + newParam;
        }
      }
      history.pushState({}, '', baseUrl + params);
    },

    refreshTestimony() {
      return BojAPI.getTestimony(this.testimony.id)
      .then((response) => {
        this.setData(response.data)
      })
      .catch((error) => {
        console.log('Error: refreshTestimony');
      })
    },
    setData(payload) {
      this.testimony = payload.data;
    },
    setStoreData() {
      this.testimonyTypes = this.$store.getters.options.testimony_types;
    }
  }
}
</script>

<style>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}

.component-fade-enter-from,
.component-fade-leave-to {
  opacity: 0;
}
</style>
